<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click.stop="dialog = true" text>
          <i class="fa fa-cloud-upload" />
          <span class="hidden-md-and-down">
            &nbsp;Import
          </span>
        </v-btn>
      </template>
      <span>Import Map Markers From File</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title primary-title>
          <h4 class="headline logo_text">
            <i class="fa fa-cloud-upload logo_icon" />&nbsp;Import data for
            {{ currMap.title }}
          </h4>
        </v-card-title>

        <v-divider />

        <v-card-text v-if="dialog">
          Upload markers in JSON, TSV or CSV format.
          <br /><br />
          You can export all the markers from your map in these formats using
          the
          <i class="fa fa-cloud-download" /> button on the map page.
          <br /><br />
          Drag or click below to get started.
          <v-text-field
            v-model="fileName"
            :error-messages="errors.collect('file')"
            label="Choose file..."
            required
            @click.native="onFocus"
          />

          <div class="dropbox">
            <input
              id="file"
              ref="file"
              v-validate="'required|ext:csv,json,tsv'"
              class="input-file"
              type="file"
              name="input file"
              data-vv-name="file"
              data-vv-as="field"
              @change="fileSelect()"
              required
            />
            <p>
              Drag your file here to begin<br />
              or click to browse
            </p>
          </div>

          <v-checkbox
            v-model="override"
            :label="
              `Override all map data (Instead of adding to current map data)`
            "
          ></v-checkbox>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="importMarkers">
            Import
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Import",
  data() {
    return {
      file: null,
      fileName: "",
      override: false,
      dialog: false
    };
  },
  computed: {
    ...mapGetters(["currMap"])
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.clear();
      }
    }
  },
  methods: {
    async importMarkers() {
      const allValid = await this.$validator.validateAll();

      if (allValid) {
        await this.$store.dispatch("importData", [
          this.currMap.title,
          this.file,
          this.override
        ]);
        this.dialog = false;
        this.clear();
      }
    },
    fileSelect() {
      this.file = this.$refs.file.files[0];
      this.fileName = this.file.name;
    },
    onFocus() {
      this.$refs.file.click();
    },
    clear() {
      this.file = null;
      this.fileName = "";
      this.override = false;
      this.errors.clear();
    }
  }
};
</script>
<style scoped>
.dropbox {
  outline: 2px dashed #424168; /* the dash box */
  outline-offset: -10px;
  background: #fcbfa4;
  color: #424168;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #fa9c73; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.file_span {
  font-weight: bold;
}
</style>
